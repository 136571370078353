const Pages = Object.freeze({
  home: 'home',
  login: 'login',
  setup: 'setup',
  cookies: 'cookies',
  userPermissions: 'user-permissions',
  germinateSettings: 'germinate-settings',
  germplasm: 'germplasm',
  germplasmUnifier: 'germplasm-unifier',
  passport: 'passport',
  climates: 'climates',
  climateDetails: 'climate-details',
  traits: 'traits',
  traitDetails: 'trait-details',
  trialCreation: 'trial-creation',
  markers: 'markers',
  markerDetails: 'marker-details',
  maps: 'maps',
  mapDetails: 'map-details',
  export: 'export',
  exportCrossComparison: 'export-cross-comparison',
  exportGenotypes: 'export-genotypes',
  exportClimates: 'export-climates',
  exportTraits: 'export-trials',
  exportAlleleFrequency: 'export-allelefrequency',
  locations: 'locations',
  geographicSearch: 'geographic-search',
  datasets: 'datasets',
  experiments: 'experiments',
  experimentDetails: 'experiment-details',
  dataResources: 'data-resources',
  statistics: 'statistics',
  images: 'images',
  markedItems: 'marked-items',
  markedItemsType: 'marked-items-type',
  importUpload: 'import-upload',
  importUploadType: 'import-upload-type',
  search: 'search',
  searchQuery: 'search-query',
  publications: 'publications',
  publicationDetails: 'publication-details',
  stories: 'stories',
  groups: 'groups',
  groupDetails: 'group-details',
  groupUpload: 'group-upload',
  aboutGerminate: 'about-germinate',
  aboutProject: 'about-project',
  aboutExportFormats: 'about-export-formats',
  aboutExportFormatsType: 'about-export-formats-type',
  userFeedback: 'user-feedback',
  fourZeroThree: '403',
  fourZeroFour: '404',
  fallback: 'fallback'
})

export {
  Pages
}
